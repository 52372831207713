import React from "react";
import Markdown from 'react-remarkable';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from "../../components/Layout";
import GenericPageTemplate from "../../templates/generic-page";

const TermsPrivacyPage = ({intl}) => {
  return (
    <Layout>
      <GenericPageTemplate heroTitle={intl.formatMessage({id: 'terms-privacy.hero.title'})}>
          <Markdown>{intl.formatMessage({id: 'terms-privacy.text'})}</Markdown>
      </GenericPageTemplate>
    </Layout>
  );
};

export default injectIntl(TermsPrivacyPage);
